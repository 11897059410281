import api from "../../../../services/api";

const PRODUCTS_URL = "/products";
const FIND_CONTENT_URL = "/knowledge/find";
const EVALUATIONS_FIND_URL = "/evaluation/knowledge";
const GET_CONTENT_URL = "/knowledge";
const CREATE_CONTENT_URL = "/knowledge";
const UPDATE_CONTENT_URL = "/knowledge";
const DELETE_CONTENT_URL = "/knowledge";
const FIND_ALL_CONTENT_URL = "/knowledge/list";

export function findContents(queryParams) {
    queryParams.sortOrder = queryParams.sortOrder.toUpperCase();
    return api.post(`${FIND_CONTENT_URL}`, queryParams);
}

export function getContent(id) {
  return api.get(`${GET_CONTENT_URL}/${id}`);
}
export function getEvaluations(id, queryParams) {
  return api.post(`${EVALUATIONS_FIND_URL}/${id}/find`, queryParams);
}

export function getIcon(iconUrl) {
  return api.get(iconUrl, { responseType: "blob" });
}

export function createContent(content) {
  return api.post(CREATE_CONTENT_URL, content, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function updateContent(id, content) {
  return api.put(`${UPDATE_CONTENT_URL}/${id}`, content, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function deleteContent(contentId) {
  return api.delete(`${DELETE_CONTENT_URL}/${contentId}`);
}

export function getAllProducts() {
  return api.get(PRODUCTS_URL);
}

export function getAllKnowledges() {
  return api.get(FIND_ALL_CONTENT_URL);
}

