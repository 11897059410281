import { createSlice } from "@reduxjs/toolkit";

const initialContentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  contentForEdit: undefined,
  contentForEditIcon: undefined,
  currentKnowledgeEvaluation: undefined,
  lastError: null,
  products: [],
  allContents: {},
  filter: {
    pageNumber: 1,
    pageSize: 10,
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const contentsSlice = createSlice({
  name: "contents",
  initialState: initialContentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    contentFetched: (state, action) => {
      const content = action.payload.contentForEdit;
      if (content !== undefined) {
        const contentProducts = content.products.filter(
          (product) => product.system_product_id === null
        );
        content.module = content.products.filter(
          (product) => product.system_product_id !== null
        );
        content.products = contentProducts.map(
          (product) =>
            product.system_product_id === null && {
              value: product.id,
              label: product.name,
            }
        );
        content.visibility = content.private ? "1" : "0";
        content.knowledge_type_id = content.knowledge_type_id.id.toString();
        if (content.knowledge_attachment.length > 0) {
          if (content.knowledge_type_id == 2) {
            content.video = content.knowledge_attachment[0].url;
          } else if (content.knowledge_type_id == 4) {
            content.link = content.knowledge_attachment[0].url;
          } else if (content.knowledge_type_id == 5) {
            content.link = content.knowledge_attachment[0].url;
          } else if (content.knowledge_type_id == 6) {
            content.link = content.knowledge_attachment[0].url;
          }
        }
      }
      state.actionsLoading = false;
      state.contentForEdit = content;
      state.error = null;
    },
    contentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.contentForEdit = undefined;
      state.contentForEditIcon = undefined;
    },
    contentCreated: (state, action) => {
      state.contentForEdit = undefined;
      state.contentForEditIcon = undefined;
      state.error = null;
      state.actionsLoading = false;
    },
    contentUpdated: (state, action) => {
      state.contentForEdit = undefined;
      state.contentForEditIcon = undefined;
      state.error = null;
      state.actionsLoading = false;
    },
    contentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    productsFetched: (state, action) => {
      const { products } = action.payload;
      state.products = products.map((product) => ({
        value: product.id,
        label: product.name,
        products: product.products,
      }));
      state.error = null;
      state.actionsLoading = false;
    },
    evaluationsFetched: (state, action) => {
      const {
        knowledge,
        mediaEvaluation,
        result,
        totalRecords,
      } = action.payload;
      state.currentKnowledgeEvaluation = {
        knowledge,
        mediaEvaluation,
        result: result.reverse(),
      };

      state.totalCount = totalRecords;
      state.error = null;
      state.listLoading = false;
    },
    contentIconFetched: (state, action) => {
      state.contentForEditIcon = action.payload.icon;
      state.error = null;
      state.actionsLoading = false;
    },
    paginationChanged: (state, action) => {
      const { pageNumber, pageSize } = action.payload;
      state.error = null;
      state.listLoading = false;
      state.filter.pageNumber = pageNumber;
      state.filter.pageSize = pageSize;
    },
    clear: (state, action) => {
      state.currentKnowledgeEvaluation = undefined;
      state.contentForEdit = undefined;
      state.totalCount = undefined;
      state.error = null;
      state.listLoading = false;
    },
    setAllContents: (state, action) => {
      const { allContents } = action.payload;
      state.allContents = allContents;
    },
  },
});
