import * as requestFromServer from "./contentsCrud";
import { callTypes, contentsSlice } from "./contentsSlice";

const { actions } = contentsSlice;

export const updatePagination = (pageSize, pageNumber) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  dispatch(actions.paginationChanged({ pageSize, pageNumber }));
};

export const fetchContent = (id) => async (dispatch) => {
  if (id === undefined || id === null || id === "") {
    dispatch(actions.contentFetched({ contentForEdit: undefined }));
  } else {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
      .getContent(id)
      .then((response) => {
        dispatch(actions.contentFetched({ contentForEdit: response.data[0] }));
      })
      .catch((error) => {
        error.clientMessage = "Can't find content";
        dispatch(actions.catchError({ error, callType: callTypes.action }));
      });
  }
};

export const fetchContents = (queryParams) => async (dispatch) => {
  dispatch(actions.contentsFetched({ entities: [], totalCount: 0 }));
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findContents({
      filter: queryParams.filter,
      searchText: queryParams.searchText,
      sortOrder: queryParams.sortOrder.toUpperCase(),
      sortField: queryParams.sortField,
      pageNumber: parseInt(queryParams.pageNumber) - 1,
      pageSize: queryParams.pageSize,
    })
    .then((response) => {
      dispatch(
        actions.contentsFetched({
          entities: response.data.items,
          totalCount: response.data.totalRecords,
        })
      );
    })
    .catch((error) => {
      error.clientMessage = "Can't find content";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProducts = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getAllProducts()
    .then((response) => {
      dispatch(actions.productsFetched({ products: response.data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find products";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const fetchEvaluations = (id, queryParams) => async (dispatch) => {
  if (id === undefined || id === null || id === "") {
    dispatch(actions.evaluationsFetched({ evaluations: undefined }));
  } else {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
      .getEvaluations(id, {
        filter: queryParams.filter,
        searchText: queryParams.searchText,
        sortOrder: queryParams.sortOrder.toUpperCase(),
        sortField: queryParams.sortField,
        pageNumber: parseInt(queryParams.pageNumber) - 1,
        pageSize: queryParams.pageSize,
      })
      .then((response) => {
        dispatch(
          actions.evaluationsFetched({
            knowledge: response.data.knowledge,
            mediaEvaluation: response.data.mediaEvaluation,
            result: response.data.result,
            totalRecords: response.data.totalRecords,
          })
        );
      })
      .catch((error) => {
        error.clientMessage = "Can't find evaluations";
        dispatch(actions.catchError({ error, callType: callTypes.list }));
      });
  }
};

export const createContent = (contentForCreation) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createContent(contentForCreation)
    .then((response) => {
      const content = response.data;
      dispatch(actions.contentCreated({ content }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateContent = (id, content) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateContent(id, content)
    .then(() => {
      dispatch(actions.contentUpdated({ content }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteContent = (id) => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteContent(id)
    .then(() => {
      dispatch(actions.contentDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete content";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchContentIcon = (iconUrl) => async (dispatch) => {
  if (iconUrl === "none") {
    dispatch(actions.contentIconFetched({ icon: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getIcon(iconUrl)
    .then((response) => {
      console.log(response);
      //dispatch(actions.contentDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete content";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const clear = () => (dispatch) => {
  dispatch(actions.clear());
};

export const fetchAllKnowledges = () => async (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer.getAllKnowledges().then((response) => {
    console.log({ response });
    dispatch(actions.setAllContents({ allContents: response.data.data }));
  });
};
